import { locale, plural } from "@lib/i18n/locale/nl-NL"
import { Options } from "@lib/types/i18n"

// tslint:disable:max-line-length
export const options: Options = {
	locale,
	plural,
	texts: {
		general: {
			true: "Ja",
			false: "Nee",
			validation: {
				required: "Dit veld is verplicht",
				invalidEmail: "Dit is geen geldig e-mailadres",
				invalidPhone: "Dit is geen geldig telefoonnummer",
				makeAChoice: "Maak een keuze"
			},
			error: "Er is iets fout gegaan. Probeer het opnieuw.",
			unknown: "Niet bekend",
			loading: "Laden...",
			dateFormat: {
				day: "dd",
				month: "mm",
				year: "jjjj"
			},
			backToOverview: "Terug naar het overzicht",
			yes: "Ja",
			no: "Nee",
			nvt: "N.v.t."
		},
		impersonate: {
			title: "Impersonate",
			loading: "Laden...",
			failed: "Inloggen gefaald"
		},
		welcomeCard: {
			morning: "Goedemorgen",
			afternoon: "Goedemiddag",
			evening: "Goedenavond",
			night: "Goedenacht"
		},
		highlights: {
			payPremium: {
				title: "Premie betalen",
				actionText: "Bekijk"
			},
			employees: {
				title: "Uw medewerkers",
				actionText: "Bekijk",
				noPermissionToViewData: "{{ companyName }} moet toestemming geven om inzicht te krijgen in de werknemergegevens. De werkgever kan toestemming geven via het werkgeverportaal."
			},
			pensionScheme: {
				title: "De pensioenregeling",
				actionText: "Bekijk"
			}
		},
		inbox: {
			title: "Inbox",
			noMessages: "U heeft nog geen berichten van ons ontvangen."
		},
		profileWidget: {
			title: "Profiel",
			email: "E-mailadres",
			telephone: "Telefoonnummer",
			advisor: "Adviseur",
			toProfile: "Naar profiel"
		},
		arrangeImmediatelyWidget: {
			title: "Direct regelen",
			firstTime: "Eerste bezoek?",
			employeeSignup: "Aanmelden medewerkers",
			employeeSignoff: "Afmelden medewerkers",
			employeeDetails: "Gegevens medewerkers",
			authorizeAdvisor: "Machtigen adviseur",
			changeMyDetails: "Mijn gegevens wijzigen",
			changeCompanyDetails: "Bedrijfsgegevens wijzigen"
		},
		activityWidget: {
			title: "Laatst bekeken",
			noActivity: "U heeft nog geen documenten bekeken."
		},
		messagesWidget: {
			title: "Inbox",
			noMessages: "Er zijn nog geen berichten beschikbaar in uw inbox.",
			toMessages: "Naar inbox"
		},
		documentsWidget: {
			inbox: {
				title: "Inbox",
				toInbox: "Naar inbox",
				noMessagesAvailable: "Er zijn nog geen berichten beschikbaar in uw inbox."
			},
			documentsArchive: {
				title: "Documenten archief",
				toDocumentsArchive: "Naar documenten archief",
				noDocumentsAvailable: "Er zijn nog geen documenten beschikbaar in uw documenten archief."
			}
		},
		footer: {
			disclaimer: "Disclaimer",
			cookies: "Cookieverklaring",
			privacy: "Privacyverklaring",
			contact: "Contact",
			termsOfUse: "Gebruiksvoorwaarden"
		},
		customerJourney: {
			intro: {
				buttonText: {
					PENDING: "Start",
					STARTED: "Ga verder",
					COMPLETED: "Start"
				}
			},
			outro: {
				buttonDone: "Klaar!",
				choose: "Kies"
			}
		},
		newEmployer: {
			intro: {
				title: "Welkom bij Scildon",
				info: "Welkom bij Scildon. U heeft uw pensioenregeling bij Scildon ondergebracht. Het Scildon Pensioen combineert de voordelen van een collectieve pensioenregeling met individuele keuzevrijheid. Scildon Pensioen is een duidelijk product. Duidelijke kosten, geen kleine lettertjes en geen moeilijk taalgebruik. Alle informatie over uw Scildon Pensioen vindt u in uw documenten archief in het portaal. We gaan u helpen om de laatste zaken voor uw pensioenadministratie te regelen"
			},
			provideParticipantData: {
				UPA: {
					title: "U zorgt voor de juiste gegevens van uw medewerkers",
					info: "Om er voor te zorgen dat uw medewerkers het juiste pensioen opbouwen en u de juiste premie betaalt, zorgt u er voor dat Scildon de juiste gegevens van uw medewerkers heeft. U levert daarom periodiek de gegevens uit uw salarisadministratie aan. U heeft er voor gekozen om dit via de Uniforme Pensioenaangifte (UPA) te regelen. Uw salarispakket moet u hiervoor inrichten.",
					explanationTitle: "Gegevens voor uw salarispakket",
					packageNumber: "Regelingnummer",
					packageCharacteristic: "Regelingkenmerk",
					packageVariant: "Regelingvariant"
				},
				BENEFITS_PLAZA: {
					title: "Uw adviseur zorgt voor de juiste gegevens van uw medewerkers",
					info: "Om er voor te zorgen dat uw medewerkers het juiste pensioen opbouwen en u de juiste premie betaalt, zorgt uw adviseur er voor dat Scildon de juiste gegevens van uw medewerkers heeft. Uw adviseur levert daarom periodiek de gegevens aan. U heeft er voor gekozen om dit via BenefitsPlaza te regelen."
				},
				PORTAL: {
					title: "U zorgt voor de juiste gegevens van uw medewerkers",
					info: "Om er voor te zorgen dat uw medewerkers het juiste pensioen opbouwen en u de juiste premie betaalt, zorgt u er voor dat Scildon de juiste gegevens van uw medewerkers heeft. U levert daarom periodiek de gegevens uit uw salarisadministratie aan. U heeft er voor gekozen om de gegevens via het portaal aan te leveren.",
					buttonText: "Direct regelen"
				}
			},
			makingPayment: {
				title: "De rekening betalen",
				info: "U betaalt via rekening courant de pensioenpremie aan Scildon. De kosten en premie van het pensioen worden hier periodiek verwerkt. U ontvangt een notificatie als de nota klaar staat. In uw portaal ziet u ook het saldo van de rekening courant."
			},
			authorizeAdvisor: {
				title: "Geef uw adviseur inzicht in uw pensioenadministratie",
				info: "Uw adviseur heeft de pensioenregeling voor u afgesloten. U kunt uw adviseur inzicht geven in uw pensioenadministratie. Uw adviseur ziet dan de gegevens van uw medewerkers terug op het adviseursportaal. U kunt de machtiging altijd wijzigen.",
				send: "Verstuur",
				success: {
					title: "Gelukt!",
					info: "We hebben uw machtiging verwerkt."
				}
			},
			contactDetails: {
				title: "Contactgegevens",
				info: "Scildon informeert u digitaal over uw pensioenadministratie. Zorg ervoor dat uw contactgegevens juist zijn.",
				pleaseFill: "Vul uw contactgegevens in",
				email: "E-mailadres",
				telephone: "Telefoonnummer",
				send: "Verstuur",
				success: {
					title: "Gelukt!",
					info: "We hebben uw contactgegevens verwerkt."
				}
			},
			outro: {
				title: "Dat was het voor nu!",
				info: "Via Scildon Online Pensioenen helpen we u om uw pensioenadministratie zo gemakkelijk mogelijk te maken. We danken u voor het vertrouwen, we hopen dat u nog vele jaren klant blijft bij Scildon!",
				card1: {
					title: "De pensioenpremie",
					info: "Overzicht van uw rekening courant"
				},
				card2: {
					title: "Uw medewerkers",
					info: "Een overzicht van uw medewerkers"
				},
				card3: {
					title: "Aanmelden medewerkers",
					info: "Geef de gegevens van uw medewerkers door"
				},
				card4: {
					title: "De pensioenregeling",
					info: "De details van uw pensioenregeling"
				}
			}
		},
		signUpEmployees: {
			intro: {
				start: {
					title: "Geef de gegevens van uw medewerkers door",
					info: "Om ervoor te zorgen dat uw medewerkers het juiste pensioen opbouwen en u de juiste premie betaalt, is het belangrijk dat Scildon de juiste gegevens van uw medewerkers heeft. U gaat de eerste keer de gegevens van uw medewerkers aanleveren."
				},
				signUp: {
					title: "Meld uw nieuwe medewerkers aan",
					info: "Als u nieuwe medewerkers in dienst neemt, moet u deze medewerkers ook aanmelden voor de pensioenregeling. U kunt de nieuwe medewerkers hier aanmelden."
				},
				yearlySalaryChange: {
					title: "Geef de salarisverhogingen door",
					info: "Het nieuwe jaar is van start gegaan. Voor veel werkgevers het moment om een salarisverhoging toe te kennen. Om er voor te zorgen dat uw medewerkers het juiste pensioen opbouwen en u de juiste premie betaalt, zorgt u er voor dat Scildon de juiste gegevens van uw medewerkers heeft."
				}
			},
			provideEmployeeData: {
				start: {
					title: "Geef de gegevens van uw medewerkers door",
					info: "In een aantal stappen kunt u de gegevens van uw medewerkers doorgeven. Eerst downloadt u het template. Vervolgens kunt u met behulp van de handleiding de gegevens op de juiste manier invullen."
				},
				signUp: {
					title: "Meld uw nieuwe medewerkers aan",
					info: "Als u nieuwe medewerkers in dienst neemt, moet u deze medewerkers ook aanmelden voor de pensioenregeling. U kunt de nieuwe medewerkers hier aanmelden."
				},
				yearlySalaryChange: {
					title: "Geef de wijzigingen door",
					info: "In een aantal stappen kunt u de gegevens van uw medewerkers doorgeven. Eerst vult u de datum vanaf wanneer de wijzigingen gelden en daarna downloadt u het template. Vervolgens kunt u met behulp van de handleiding de gegevens op de juiste manier invullen. "
				},
				downloadTemplate: {
					title: "Download het template",
					label: "Download template",
					download: "Download",
					year: "Vanaf welke datum gelden deze wijzigingen?",
					validation: {
						year: {
							required: "Dit veld is verplicht",
							correctYear: "U dient een geldig jaar in te invullen",
							higherOrEqualsMigrationYear: "U kunt geen wijzigingen voor het jaartal {{ year }} doorgeven",
							lowerOrEqualsCurrentYear: "Het jaartal mag niet in de toekomst liggen"
						}
					}
				},
				wizardControls: {
					previous: "Vorige",
					next: "Volgende"
				},
				explanationSignupText: "<b>LET OP:</b><br/><br/>Meldt u meerdere nieuwe medewerkers aan, met een datum aanvang deelname in " +
						"verschillende kalenderjaren?<br/><br/>" +
						"Maak dan per kalenderjaar een apart template aan. En upload deze in chronologische volgorde.",
				uploadParticipantData: {
					label: "Upload de gegevens van de deelnemers",
					upload: "Upload",
					finish: "afronden",
					uploadSuccess: {
						title: "Terugkoppeling",
						description: "Het bestand is succesvol verwerkt"
					},
					uploadError: {
						title: "Terugkoppeling",
						description: "Het bestand is niet verwerkt",
						columns: {
							number: "Huisnummer"
						},
						error: {
							general: "Het bestand wijkt af van het template",
							required: "Rij {{ row }}, kolom {{ column }} bevat een fout",
							LessThanZero: "Rij {{ row }}, kolom {{ column }} bevat een fout",
							MoreThanOneHundred: "Rij {{ row }}, kolom {{ column }} bevat een fout",
							invalid: "Rij {{ row }}, kolom {{ column }} bevat een fout",
							generic: "bevat fouten",
							policy: "Kan geen overeenkomend polis vinden voor deelnamenummer",
							empty: "Het bestand is leeg",
							zipCode: "Foutieve combinatie postcode",
							andHousenumber: "en huisnummer",
							generalMessage: "Er is iets misgegaan, niet alle ingevoerde waarden in het werknemerbestand zijn correct"
						}
					},
					uploadWarning: {
						title: "Terugkoppeling",
						description: "De medewerkers in de hieronder genoemde regels zijn niet verwerkt. Corrigeer de gegevens en upload het bestand opnieuw.",
						error: {
							default: "De ingevulde gegevens zijn niet juist",
							invalidZipcode: "De postcode is niet geldig",
							elfproefFailure: "Het BSN is niet geldig",
							personDateOfBirthIsInFuture: "De geboortedatum kan niet in de toekomst liggen",
							personDateOfBirthAfterStartOfEmployment: "De geboortedatum kan niet later zijn dan de startdatum van het dienstverband",
							employmentStartDateError: "De datum in dienst is niet geldig",
							packageStartAfterPeriodEndError: "De datum aanvang deelname kan niet in de toekomst liggen",
							incomePeriodStartDateIsInTheFuture: "De datum in dienst is niet geldig",
							wrongPeriod: "De aangeleverde periode komt niet overeen met de aanleverfrequentie."
						}
					},
					emptySheetError: "Het bestand is leeg. "
				},
				success: {
					start: {
						title: "Gelukt!",
						info: "De door u toegevoegde deelnemers zullen de eerst volgende werkdag in uw portaal zichtbaar zijn. Wij zullen de gegevens van uw medewerkers verwerken en ze een uitnodiging voor het portaal toesturen. Inloggen in het portaal kan via DigiD, hiervoor ontvangen uw werknemers dus geen aparte inloggegevens."
					},
					signUp: {
						title: "Gelukt!",
						info: "De door u toegevoegde deelnemers zullen de eerst volgende werkdag in uw portaal zichtbaar zijn. Wij zullen de gegevens van uw medewerkers verwerken en ze een uitnodiging voor het portaal toesturen. Inloggen in het portaal kan via DigiD, hiervoor ontvangen uw werknemers dus geen aparte inloggegevens."
					},
					yearlySalaryChange: {
						title: "Gelukt!",
						info: "Wij zullen de gegevens van uw medewerkers wijzigen. "
					}
				},
				columnNameInfo: {
					title: "Toelichting van de kolommen in excel",
					info: "U leest hier bij elke kolom waar u rekening mee moet houden bij het invullen van het template."
				},
				instructions: {
					start: {
						bsn: {
							title: "BSN",
							explanation: "U vult hier het Burger Service Nummer (BSN) van uw medewerker in. Als het BSN (nog) niet bekend is of uw medewerker heeft (nog) geen BSN laat het veld dan leeg. U moet dan wel verplicht het personeelsnummer invullen."
						},
						nrIKV: {
							title: "Nr IKV",
							explanation: "U vult hier het nummer van de inkomensverhouding van uw medewerker in. In de meeste gevallen is dit ‘1’. Heeft uw medewerker in het verleden ook al eens bij u gewerkt, dan zal het nummer van de inkomensverhouding ‘2’ of zelfs hoger zijn. Dit is afhankelijk van het aantal dienstverbanden van uw medewerker bij uw bedrijf. Zorg dat voor elke medewerker <i>Nr IKV</i> is ingevuld. "
						},
						personeelsnummer: {
							title: "Personeelsnummer",
							explanation: "U vult hier het personeelsnummer van uw medewerker in. Als u geen personeelsnummer heeft kunt u het veld leeg laten. U moet dan wel verplicht het BSN invullen."
						},
						voorletters: {
							title: "Voorletters",
							explanation: "U vult hier de voorletters van uw medewerker in. Zorg dat voor elke medewerker <i>Voorletters</i> is ingevuld."
						},
						tussenvoegsel: {
							title: "Tussenvoegsel",
							explanation: "U vult hier alleen het tussenvoegsel van de <i>geboorte achternaam</i> van uw medewerker in. Als er geen tussenvoegsel is laat u het veld leeg."
						},
						achternaam: {
							title: "Achternaam",
							explanation: "U vult hier alleen de <i>geboorte achternaam</i> van uw medewerker in."
						},
						straatnaam: {
							title: "Straatnaam",
							explanation: "U vult hier de straatnaam van uw medewerker in. Zorg dat voor elke medewerker <i>Straatnaam</i> is ingevuld."
						},
						huisnummer: {
							title: "Huisnummer",
							explanation: "U vult hier het huisnummer van uw medewerker in. Zorg dat voor elke medewerker <i>Huisnummer</i> is ingevuld."
						},
						huisnummerToevoeging: {
							title: "Huisnummer toevoeging",
							explanation: "U vult hier de huisnummer toevoeging van uw medewerker in. U hoeft de toevoeging alleen maar in te vullen voor medewerkers waar dit van toepassing is."
						},
						postcode: {
							title: "Postcode",
							explanation: "U vult hier de postcode van uw medewerker in. Zorg dat voor elke medewerker <i>Postcode</i> is ingevuld."
						},
						woonplaats: {
							title: "Woonplaats",
							explanation: "U vult hier de woonplaats van uw medewerker in. Zorg dat voor elke medewerker <i>Woonplaats</i> is ingevuld."
						},
						landcode: {
							title: "Landcode",
							explanation: "De landcode is standaard ingevuld voor Nederland (NL). Als uw medewerker niet in Nederland woont, moet u een andere landcode invullen. Gebruik hiervoor de ISO 3166-2 landcode."
						},
						email: {
							title: "E-mail",
							explanation: "Vul het e-mailadres van uw medewerker in. Uw medewerker ontvangt op dit e-mailadres notificaties van Scildon. De medewerker kan het e-mailadres zelf wijzigen in Scildon Online Pensioenen."
						},
						geboorteDatum: {
							title: "Geboortedatum",
							explanation: "U vult hier de geboortedatum van uw medewerker in. Zorg dat voor elke medewerker <i>Geboortedatum</i> is ingevuld."
						},
						geslacht: {
							title: "Geslacht",
							explanation: "U vult hier het geslacht van uw medewerker in. Voor mannen vult u ‘M’ in, voor vrouwen gebruikt u ‘V’. Zorg dat voor elke medewerker <i>Geslacht</i> is ingevuld. "
						},
						datumInDienst: {
							title: "Datum in dienst",
							explanation: "U vult hier de datum in waarop uw medewerker bij u in dienst is getreden. Deze datum kan vóór de startdatum van de pensioenregeling liggen. Zorg dat voor elke medewerker <i>Datum in dienst</i> is ingevuld."
						},
						datumAanvangDeelname: {
							title: "Datum aanvang deelname",
							explanation: "Vul de datum in waarop uw medewerker gaat deelnemen aan de pensioenregeling. De datum kan nooit voor de startdatum van het dienstverband liggen. De datum kan nooit voor de startdatum van de pensioenregeling liggen. "
						},
						jaarlijksPensioengevendLoon: {
							title: "Jaarlijks Pensioengevend loon",
							explanation: "U vult hier het fulltime jaarlijks pensioengevend loon van uw medewerker in. Houd rekening met de extra pensioengevende salariscomponenten, zoals bijvoorbeeld de vakantietoeslag of een 13e maand. Voor medewerkers die parttime werken, vult u ook het fulltime pensioengevend loon in. Zorg dat voor elke medewerker <i>Jaarlijks Pensioengevend loon</i> is ingevuld."
						},
						parttimepercentage: {
							title: "Parttime%",
							explanation: "U vult hier het parttimepercentage van uw medewerker in. Als uw medewerker fulltime werkt, is het percentage 100. Zorg dat voor elke medewerker <i>Parttime%</i> is ingevuld. "
						}
					},
					yearlySalaryChange: {
						nieuwParttimepercentage: {
							title: "Nieuw parttimepercentage",
							explanation: "Vul het nieuwe parttimepercentage in. Als het parttimepercentage niet is gewijzigd, vult u hier het huidige parttimepercentage in. "
						},
						nieuwPensioengevendJaarloon: {
							title: "Nieuw pensioengevend jaarloon",
							explanation: "Vul het nieuwe fulltime pensioengevend jaarloon in. Als het fulltime pensioengevend jaarloon niet is gewijzigd, vult u hier het huidige fulltime pensioengevend jaarloon in. "
						}
					}
				}
			},
			outro: {
				start: {
					title: "Uw pensioenregeling kan nu van start gaan",
					info: "U heeft nu alles geregeld voor het pensioen van uw medewerkers. Uw medewerkers krijgen toegang tot Scildon Online Pensioenen. In deze persoonlijke omgeving krijgen uw medewerkers informatie over de pensioenregeling, inzicht in de pensioengegevens en worden uw medewerkers stap voor stap begeleid bij belangrijke pensioengebeurtenissen en keuzes. "
				},
				signUp: {
					title: "Uw nieuwe medewerkers nemen nu deel aan de pensioenregeling",
					info: "U heeft nu alles geregeld voor het pensioen van uw nieuwe medewerkers. Uw nieuwe medewerkers krijgen toegang tot Scildon Online Pensioenen. In deze persoonlijke omgeving krijgen uw medewerkers informatie over de pensioenregeling, inzicht in de pensioengegevens en worden uw medewerkers stap voor stap begeleid bij belangrijke pensioengebeurtenissen en keuzes."
				},
				yearlySalaryChange: {
					title: "De gegevens van uw medewerkers zijn gewijzigd",
					info: "U heeft nu alles geregeld voor het wijzigen van de gegevens van uw medewerkers."
				},
				card1: {
					title: "Help uw medewerkers op weg",
					info: "Zorg voor een vliegende pensioenstart van uw medewerkers."
				},
				card2: {
					title: "De pensioenpremie",
					info: "Overzicht van uw rekening courant."
				},
				card3: {
					title: "Uw medewerkers",
					info: "Een overzicht van uw medewerkers."
				}
			}
		},
		payPremium: {
			title: "Premie betalen",
			remainingAmount: {
				title: "Openstaand bedrag"
			},
			paymentMethod: {
				title: "Betaalwijze",
				method: "U betaalt via rekening courant",
				bankAccountNumber: "Bankrekeningnummer",
				paymentReference: "Uw betalingskenmerk",
				employerId: "WerkgeverID"
			},
			overview: {
				title: "Overzicht rekening courant",
				noTransactions: "Er zijn geen transacties bekend.",
				column: {
					date: "Datum",
					description: "Omschrijving",
					amount: "Bedrag",
					explanation: "Toelichting"
				},
				description: {
					ReceivableCashVoucher: "Nota",
					ContributorReceivableVoucher: "Storting"
				},
				explanation: {
					ContributorReceivableVoucher: "U heeft geld gestort op de rekening courant",
					ReceivableCashVoucher: "In uw documentenarchief kunt u de nota downloaden"
				}
			}
		},
		profile: {
			title: "Profiel",
			tabs: {
				myDetails: "Mijn gegevens",
				myCompany: "Mijn bedrijf",
				myAdvisor: "Mijn adviseur"
			},
			myDetails: {
				name: "Naam",
				email: "E-mailadres",
				phoneNumber: "Telefoonnummer",
				save: "Opslaan",
				success: "Contactgegevens opgeslagen!",
				error: "Er is iets fout gegaan tijdens het opslaan van de contactgegevens.",
				validation: {
					name: {
						required: "De naam is verplicht"
					},
					email: {
						required: "Het e-mailadres is verplicht",
						pattern: "Het e-mailadres is niet geldig"
					}
				}
			},
			myCompany: {
				companyName: "Bedrijfsnaam",
				relationNumber: "Relatienummer",
				chamberOfCommerceNumber: "KvK-nummer",
				payrollTaxNumber: "Loonheffingsnummer",
				businessAddress: "Vestigingsadres",
				postalAddress: "Postadres",
				save: "Opslaan",
				success: "Bedrijfsgegevens opgeslagen!",
				error: "Er is iets fout gegaan tijdens het opslaan van de bedrijfsgegevens."
			},
			companyAddress: {
				streetName: "Straatnaam",
				number: "1",
				letter: "Letter",
				suffix: "Toevoeging",
				postalCode: "3000 AB",
				city: "Rotterdam"
			},
			myAdvisor: {
				title: "Mijn adviseur",
				companyName: "Bedrijfsnaam",
				address: "Adres",
				license: "Licentie",
				contactPerson: "Contactpersoon",
				name: "Naam",
				email: "E-mailadres",
				phoneNumber: "Telefoonnummer",
				permissions: "Machtigingen",
				givePermissions: {
					label: "Ik geef {{ companyName }} inzicht in mijn pensioenadministratie",
					tooltip: "U geeft hiermee toestemming om beperkte privacygevoelige informatie van uw werknemers zichtbaar te maken in het portaal van uw pensioenadviseur. In het kader van de privacywetgeving (Algemene Verordening Gegevensbescherming, AVG) dient u hiervoor een verwerkersovereenkomst en een dienstverleningsovereenkomst met uw adviseur te sluiten. Maak van deze mogelijkheid alleen gebruik als uw adviseur beheerwerkzaamheden voor u uitvoert."
				},
				giveAdministrationPermissions: {
					label: "Ik geef {{ companyName }} toestemming om mijn pensioenadministratie te doen"
				}
			}
		},
		employees: {
			title: "Uw medewerkers",
			tabs: {
				overview: "Overzicht medewerkers",
				changes: "Wijzigen/inzien",
				choices: "Individuele keuzes"
			},
			overview: {
				somethingWentWrong: "Er klopt iets niet aan de volgende kolom:",
				missing: "ONTBREEKT!",
				column: {
					policyNumber: "Deelnamenummer",
					fullName: "Naam",
					dateOfBirth: "Geboortedatum",
					civilStatus: "Burgerlijke staat",
					startDateEmployment: "In dienst",
					salary: "Salaris",
					fte: "FTE"
				},
				civilStatus: {
					SINGLE: "Alleenstaand",
					COHABITATION: "Samenwonend",
					LEGAL_COHABITATION: "Samenwonend met contract",
					CIVIL_PARTNERSHIP: "Geregistreerd partnerschap",
					MARRIED: "Gehuwd"
				},
				search: "Zoeken",
				signUpEmployees: "Aanmelden medewerkers",
				button: "Wijzigen"
			},
			changes: {
				table: {
					title: "Wijzig de gegevens van één medewerker",
					subTitle: "Selecteer de medewerker die u wilt wijzigen"
				},
				form: {
					title: "Wijzig de gegevens van {{ name }}",
					subTitle: "Wat wilt u wijzigen?",
					employeeChangeOptions: {
						salary_and_or_parttimepercentage: "Salaris en/of parttimepercentage",
						retirement: "Uitdiensttreding"
					},
					send: "Verstuur",
					cancel: "Annuleren",
					back: "Terug",
					retirementDate: {
						label: "Datum einde dienstverband",
						tooltip: "De laatste dag van het dienstverband",
						closeBtnText: "Annuleren",
						okBtnText: "Ok",
						threeMonthWarning: "Let op, de opgegeven datum ligt meer dan 3 maanden in de toekomst. Wilt u doorgaan of wijzigen?",
						twoMonthWarning: "Let op, de opgegeven datum ligt meer dan 2 maanden in het verleden. Wilt u doorgaan of wijzigen?"
					},
					invalidDate: "De datum is niet geldig",
					salaryAndOrPartTimePercentageChange: "Wijziging salaris en/of parttimepercentage",
					salary: "Nieuw fulltime pensioengevend salaris",
					parttimePercentage: "Nieuw parttimepercentage",
					date: "Ingangsdatum wijziging",
					error: {
						employmentEndDate: "De mutatiedatum moet in het huidige kalenderjaar liggen",
						startDate: "De mutatiedatum moet in het huidige kalenderjaar liggen"
					},
					explanationMutationsText: "<b>LET OP:</b><br/>Geeft u een wijziging in het salaris en/of het parttimepercentage door met een wijzingsdatum in het verleden? " +
						"En heeft u eerder een wijziging doorgegeven met een latere wijzigingsdatum? " +
						"Dan moet u voor de latere wijzingsdatum opnieuw het juiste salaris en/of parttimepercentage aanleveren.<br/><br/>" +
						"Voorbeeld:<br/>U geeft een salarisaanpassing door per 01.05.2022.<br/>" +
						"U geeft later nog een aanpassing door van het parttimepercentage van 100% naar 80% per 01.01.2022.<br/>" +
						"Dan moet u per 01.05.2022 nog een keer het parttimepercentage aanpassen naar 80%."
				},
				confirmation: {
					title: "Bevestiging - Wijzig de gegevens van {{ name }}",
					description: {
						salary_and_or_parttimepercentage: "U heeft het salaris en/of parttimepercentage van uw medewerker gewijzigd. Zodra de wijziging is verwerkt, ziet u dit terug in het overzicht van uw medewerkers.",
						retirement: "U heeft uw medewerker uit dienst gemeld. U betaalt geen premie meer voor deze medewerker. Het pensioen van uw medewerker blijft bij Scildon, tenzij uw medewerker een andere keuze maakt. Zodra de wijziging is verwerkt, ziet u dit terug in het overzicht van uw medewerkers."
					},
					done: "Afsluiten"
				},
				quickLinks: {
					signUpEmployee: "Aanmelden medewerker",
					changeEmployeeDetails: "Wijzig de gegevens van één medewerker",
					yearlySalaryChange: "Jaarlijkse salarisverhogingen doorgeven",
					individualChoices: "Bijsparen"
				},
				validation: {
					required: "Dit veld is verplicht",
					salary: {
						minimum: "Het salaris moet minimaal 1 euro bedragen"
					},
					parttimePercentage: {
						minimum: "Het parttimepercentage moet minimaal 1% zijn",
						maximum: "Het parttimepercentage mag maximaal 100% zijn"
					}
				}
			},
			choices: {
				tabs: {
					oneOff: "Eenmalig bijsparen",
					periodic: "Periodiek bijsparen"
				},
				extraSavingsOneOff: "Hier ziet u welke medewerkers hebben gekozen voor eenmalig bijsparen. Het bijspaarbedrag wordt bij u in rekening gebracht. U houdt het bijspaarbedrag in op het bruto salaris van uw medewerker.",
				extraSavingsPeriodic: "Hier ziet u welke medewerkers hebben gekozen voor periodiek bijsparen. Het bijspaarbedrag wordt elke maand bij u in rekening gebracht. U houdt het bijspaarbedrag elke maand in op het bruto salaris van uw medewerker.",
				column: {
					applicationDateTime: "Aanvraagdatum",
					eventDate: "Startdatum",
					type: "Soort wijziging",
					policyId: "Deelnamenummer",
					name: "Naam",
					amountOneOff: "Eenmalig bedrag",
					amountPeriodic: "Bedrag per maand",
					status: "Status",
					empty: "Er zijn geen aanvragen"
				},
				savingsRequestTypes: {
					START: "Start",
					STOP: "Einde",
					CHANGE: "Wijziging"
				},
				savingsRequestStatusses: {
					PROCESSED: "Verwerkt",
					PENDING: "Nog verwerken"
				}
			}
		},
		pensionScheme: {
			title: "De pensioenregeling",
			basicData: "Basisgegevens",
			endDate: "Einddatum",
			startDate: "Startdatum",
			participationInThePensionScheme: "Deelname aan de pensioenregeling",
			accessionAge: "Toetredingsleeftijd",
			pensionAge: "Pensioenleeftijd",
			pensionBases: "Pensioengrondslagen",
			franchise: "Franchise",
			maximumPensionableSalary: "Maximaal pensioengevend salaris",
			pensionableSalaryComponents: "Pensioengevende salariscomponenten",
			payPeriod: "Verloningsperiode",
			availablePremium: "Beschikbare premie",
			relativeToSalary: "t.o.v. het salaris",
			premiumPercentage: "van de pensioengrondslag",
			graduatedScale: {
				label: "Type staffel",
				AGE_DEPENDENT: "Leeftijdsafhankelijke staffel",
				FIXED_PREMIUM: "Gelijkblijvend percentage",
				TRANSITIONAL_ARRANGEMENT: "Overgangsregeling"
			},
			percentage: "Rekenrente premiestaffel",
			gradingPercentage: "Staffelpercentage",
			fixedBasePremium: "Gelijkblijvend percentage voor alle medewerkers",
			fixedBasePremiumNew: "Gelijkblijvend percentage voor nieuwe medewerkers",
			utilizationRate: "Benuttingspercentage",			
			employeeContribution: "Bijdrage medewerker",
			employeeContributionOwn: "Eigen bijdrage medewerker",
			ofPremium: "{{ percentage }}% van de premie",
			ofPension: "{{ percentage }}% van de pensioengrondslag",
			partnerAndOrphanPension: "Partner- en wezenpensioen",
			typeOfPartnerAndOrphanPension: {
				label: "Type partner- en wezenpensioen",
				RISK_INSURANCE: "Op risicobasis verzekerd",
				RESTITUTION: "90% restitutie van het kapitaal"
			},
			paymentTerm: "Betaaltermijn",
			paymentTermValues: {
				MONTHLY: "Maandelijks",
				QUARTERLY: "Kwartaal",
				BI_ANNUALLY: "Halfjaarlijks",
				YEARLY: "Jaarlijks"
			},
			survivorsPension: {
				WTP: {
					partnerAccrual: "Verzekerd partnerpensioen",
					orphanAccrual: "Verzekerd wezenpensioen",
					indexation: "Percentage na-indexatie"
				},
				WNP: {
					partnerAccrual: "Opbouwpercentage partnerpensioen",
					orphanAccrual: "Opbouwpercentage wezenpensioen",
					indexation: "Indexatie na uitkering"
				}
			},
			additionRegulation: "Aanvullend regelen",
			anwShortfallInsurance: "Anw-hiaatverzekering",
			savePeriodically: "Periodiek bijsparen",
			waiverOfPremiumIncapacityOfWork: "Premievrijstelling bij arbeidsongeschiktheid",
			investmentChoices: "Beleggingskeuzes",
			investmentFreedom: {
				label: "Beleggingsvrijheid",
				NONE: "Geen beleggingsvrijheid",
				LIMITED: "Beperkte beleggingsvrijheid",
				COMPLETE: "Volledige beleggingsvrijheid"
			},
			fundSelection: "Fondskeuze",
			contractDetails: "Contractdetails",
			schemeNumber: "Regelingnummer",
			schemeCharacteristic: "Regelingkenmerk",
			schemeVariant: "Regelingvariant",
			dataDelivery: "Methode van data-aanlevering",
			dataDeliveryMethod: {
				UPA: "Werkgever via UPA",
				PORTAL: "Werkgever via portaal",
				BENEFITS_PLAZA: "Intermediair via BenefitsPlaza"
			},
			year: "jaar",
			yearsOfServiceDetermination: {
				label: "Aantal dienstjaren",
				IN_SERVICE_DATE: "Op basis van de datum	indiensttreding",
				START_DATE_PENSION_SCHEME: "Op basis van de startdatum van de pensioenregeling"
			},
			anwGap: {
				title: "Anw-hiaatverzekering",
				defaultInsured: "Is de deelnemer standaard verzekerd?",
				coverageOptions: "Welke bedragen kan de deelnemer kiezen om te verzekeren?",
				coverageDefault: "Welk bedrag wordt standaard voor de deelnemer verzekerd?",
				indexation: "Indexatie na uitkering",
				hasEmployeeContribution: "Betaalt de deelnemer een eigen bijdrage?",
				defaultInsuredOptions: {
					true: "Ja (de deelnemer kan kiezen om de verzekering op te zeggen)",
					false: "Nee (de deelnemer kan kiezen om de verzekering af te sluiten)"
				},
				coverageOption: "{{ percentage }}% van de uitkering"
			}
		},
		login: {
			title: "Inloggen bij Scildon Online Pensioenen",
			body: "U regelt de pensioenzaken voor uw medewerkers digitaal. Als werkgever kunt u hier inloggen bij Scildon Online Pensioenen.",
			username: "Gebruikersnaam",
			password: "Wachtwoord",
			submit: "Log in",
			serverError: "Uw pensioenregeling is nog niet compleet. Neem contact op met Scildon.",
			loading: "Uw gegevens worden opgehaald...",
			error: {
				UNKNOWN: "Uw inloggegevens zijn niet juist.",
				ACCOUNT_LOCKED: "Uw account is voor 15 minuten geblokkeerd, omdat u 5 keer de verkeerde inloggegevens heeft ingevuld.",
				USER_DISABLED: "Uw account is geblokkeerd.",
				PASSWORD_EXPIRED: "Uw wachtwoord is verlopen.",
				ACCOUNT_EXPIRED: "Uw account is verlopen."
			},
			links: {
				activate: "Eerste bezoek? Activeer uw account",
				forgotPassword: "Wachtwoord vergeten?",
				forgotUsername: "Gebruikersnaam vergeten?"
			},
			emailVerification: {
				verificationCode: "Verificatiecode",
				title: "Vul uw verificatiecode in",
				body: "U heeft een e-mail ontvangen. In de e-mail staat een code. Vul deze code hieronder in.",
				verificationError: "Uw verificatiecode is niet geldig.",
				nextButton: "Verder"
			},
			eRecognition: {
				link: "Inloggen met eHerkenning",
				description: "Meer zekerheid over uw online identiteit, daarom gebruiken wij eHerkenning. Met eHerkenning identificeert u zich veilig en eenvoudig online. Het grote gemak is dat u met eHerkenning bij meerdere organisaties kunt inloggen. U hoeft dus minder wachtwoorden te onthouden. Veilig, makkelijk en betrouwbaar. Ga voor meer informatie naar www.eherkenning.nl."
			},
			nextButton: "Verder",
			browserCompatibility: "De pensioenportalen van Scildon zijn geoptimaliseerd voor de meest recente versies van de volgende webbrowsers: Google Chrome, MS Edge en Mozilla Firefox.",
			responseCode: {
				label: "Response code",
				submit: "Inloggen",
				description: "Bij het activeren van uw account heeft u een QR-code gescand met een app die tweestapsverificatie ondersteunt. Open de app en vul de response code in die u ziet.",
				twoStepVerification: "Tweestapsverificatie opnieuw instellen?",
				contact: "Neem contact op met uw beheerder",
				error: "Uw verificatiecode is niet geldig."
			}
		},
		activateAccount: {
			email: {
				emailAddress: "E-mailadres",
				title: "Vul uw e-mailadres in",
				body: "Vul uw e-mailadres in. U ontvangt vervolgens een e-mail met een verificatiecode."
			},
			verification: {
				verificationCode: "Verificatiecode",
				title: "Vul uw verificatiecode in",
				body: "U heeft een e-mail ontvangen. In de e-mail staat een code. Vul deze code hieronder in."
			},
			usernamePassword: {
				title: "Kies een gebruikersnaam en wachtwoord",
				body: "U gaat nu een gebruikersnaam en wachtwoord instellen. Dit wachtwoord moet minimaal 14 karakters lang zijn. Dit zijn uw persoonlijke toeganggegevens voor Scildon Online Pensioenen.",
				username: "Gebruikersnaam",
				password: "Wachtwoord",
				min: "Minimaal 14 tekens",
				usernameDuplicate: "Deze gebruikersnaam bestaat al"
			},
			qrCode: {
				title: "Stel de tweestapverificatie in",
				body: "Scan de QR-code met een app die tweestapsverificatie ondersteunt. U kunt bijvoorbeeld Google Authenticator gebruiken. Na het scannen van de QR-code krijgt u een code te zien op uw mobiele telefoon. Vul deze code hieronder in.",
				scanCode: "Scan de QR-code",
				responseCode: "Response code"
			},
			nextButton: "Verder"
		},
		forgotPassword: {
			title: "Wachtwoord vergeten",
			invalidEmail: "Het e-mailadres is niet geldig",
			form: {
				description: "Vul uw gebruikersnaam in. U ontvangt vervolgens een e-mail met een verificatiecode.",
				email: "Gebruikersnaam",
				submit: "Verzend"
			},
			changePassword: {
				description: "U kunt nu een nieuw wachtwoord instellen. Dit wachtwoord moet minimaal 14 karakters lang zijn.",
				password: "Wachtwoord",
				submit: "Wijzig",
				min: "Minimaal 14 tekens"
			},
			success: "Uw wachtwoord is gewijzigd. U kunt inloggen met uw nieuwe inloggegevens."
		},
		forgotUsername: {
			title: "Gebruikersnaam vergeten",
			invalidEmail: "Het e-mailadres is niet geldig",
			form: {
				description: "Vul uw e-mailadres in. U ontvangt vervolgens een e-mail met de gebruikersnaam dat bij ons bekend is.",
				email: "E-mailadres",
				submit: "Verzend"
			},
			changeUsername: {
				description: "U kunt nu een nieuwe gebruikersnaam instellen.",
				username: "Gebruikersnaam",
				errorUsernameInUse: "Deze gebruikersnaam bestaat al",
				submit: "Wijzig"
			},
			success: "Uw gebruikersnaam is naar het opgegeven emailadres verstuurd."
		},
		menu: {
			arrangeImmediately: {
				title: "Direct regelen",
				firstTime: "Eerste bezoek?",
				employeeSignup: "Aanmelden medewerkers",
				employeeSignoff: "Afmelden medewerkers",
				employeeDetails: "Gegevens medewerkers",
				authorizeAdvisor: "Machtigen adviseur",
				changeMyDetails: "Mijn gegevens wijzigen",
				changeCompanyDetails: "Bedrijfsgegevens wijzigen"
			 },
			 pensionAdministration: {
				 title: "Pensioenadministratie",
				 payPremium: "Premie betalen",
				 yourEmployees: "Uw medewerkers",
				 pensionScheme: "De pensioenregeling"
			 },
			 moreInformation: {
				 title: "Meer informatie",
				 glossary: "Begrippenlijst",
				 faq: "Veelgestelde vragen",
				 contact: "Contact"
			 }
		},
		documents: {
			title: "Documenten Archief",
			grid: {
				date: "datum",
				category: "Categorie",
				name: "Naam document",
				empty: "Er zijn geen documenten gevonden."
			},
			filters: {
				year: {
					label: "Jaar",
					placeholder: "Alle"
				},
				category: {
					label: "Categorie",
					placeholder: "Alle"
				}
			}
		},
		pageHeader: {
			logout: "uitloggen"
		},
		unknown: "Niet bekend",
		glossary: {
			title: "Begrippenlijst",
			fields: {
				aow: {
					title: "AOW",
					explanation: "Algemene Ouderdomswet. De AOW is een basispensioen van de overheid. Iedereen die de AOW leeftijd heeft bereikt en in Nederland woont of heeft gewoond, heeft hier recht op. De Sociale Verzekeringsbank (SVB) keert de AOW uit. Meer informatie over de AOW vindt u op {{ www.svb.nl | link }}."
				},
				aanspraakgerechtigde: {
					title: "Aanspraakgerechtigde",
					explanation: "Persoon die begunstigde is voor een nog niet ingegaan pensioen."
				},
				actueelParttimepercentage: {
					title: "Actueel parttimepercentage",
					explanation: "Zie parttimepercentage"
				},
				arbeidsongeschikt: {
					title: "Arbeidsongeschikt(heid)",
					explanation: "Voor de vraag of iemand (gedeeltelijk) arbeidsongeschikt is, volgt de verzekeraar het oordeel van het UWV. Het UWV past daarvoor de Wet werk en inkomen naar arbeidsvermogen (WIA) toe. Volgens de WIA is iemand arbeidsongeschikt als hij door een ziekte of gebrek geen gangbare arbeid kan verrichten. Meer informatie over arbeidsongeschiktheid vindt u op {{ www.uwv.nl | link }}."
				},
				begunstigde: {
					title: "Begunstigde",
					explanation: "De persoon die recht heeft op de uitkering uit de pensioenverzekering."
				},
				beheerdBelegen: {
					title: "Beheerd Beleggen",
					explanation: "Dit is een beleggingsmethode van de verzekeraar. Voor iedere (gewezen) deelnemer wordt belegd volgens een beleggingsschema. In dit schema wordt de verhouding tussen de verschillende beleggingscategorieen, zoals aandelen en obligaties (de strategische beleggingsmix) steeds in de tijd aangepast. Daarbij wordt rekening gehouden met het aantal jaren dat de beschikbare premies en het al opgebouwde pensioenkapitaal nog rendement kunnen opleveren (de beleggingshorizon). Hierdoor ontstaat een verantwoord evenwicht tussen de kans op een goed rendement en het beleggingsrisico. Duurt het nog lang totdat de (gewezen) deelnemer de pensioendatum wordt bereikt (lange beleggingshorizon), dan wordt er met de strategische beleggingsmix meer beleggingsrisico genomen. Zo is er meer kans op een hoger rendement. Er is dan nog tijd om een eventueel tegenvallend rendement op te vangen en een kans om dat goed te maken. Hoe korter de periode tot aan de pensioendatum (korte beleggingshorizon), hoe minder risicovol er wordt belegd om de kans op een tegenvallend rendement te verkleinen. De kans op een hoger beleggingsrendement neemt hierdoor af. Scildon is verantwoordelijk voor het samenstellen van een beleggingsschema met een goede balans tussen rendement en risico en voor het uitvoeren daarvan. Maar Scildon is niet verantwoordelijk voor de uiteindelijke beleggingsresultaten. Tegenvallende beleggingsresultaten komen voor rekening van de deelnemers, net als goede beleggingsresultaten."
				},
				beheerkosten: {
					title: "Beheerkosten",
					explanation: "Kosten die de verzekeraar in rekening brengt bij de (gewezen) deelnemers voor het administreren, heralloceren en herbalanceren van de beleggingen. De beheerkosten worden in mindering gebracht op het pensioenkapitaal en worden uitgedrukt als percentage van de fondswaarde."
				},
				beleggingsdepot: {
					title: "Beleggingsdepot",
					explanation: "De rekening waarop het aantal participaties per fonds wordt bijgehouden voor de pensioenverzekering."
				},
				beleggingsverhouding: {
					title: "Beleggingsverhouding",
					explanation: "De verhouding waarin bedragen aan de fondsen worden toegevoegd of onttrokken. De verzekeraar legt deze verhouding voor de eerste keer vast bij het aanmelden van de deelnemer via Scildon Online Pensioenen. De beleggingsverhouding is dan gelijk aan de strategische beleggingsmix."
				},
				beschikbarePremie: {
					title: "Beschikbare premie",
					explanation: "De premie die de werkgever heeft toegezegd voor de opbouw van het pensioenkapitaal."
				},
				brutorendement: {
					title: "Brutorendement",
					explanation: "Het behaalde beleggingsrendement door een fonds, zonder rekening te houden met de fondskosten."
				},
				deelnamebewijs: {
					title: "Deelnamebewijs",
					explanation: "Bewijs van de verzekering van pensioenaanspraken van de (gewezen) deelnemer. De (gewezen) deelnemer ontvangt het Deelnamebewijs van de verzekeraar."
				},
				deelnemer: {
					title: "Deelnemer",
					explanation: "De werknemer die pensioenaanspraken verkrijgt volgens het Pensioenreglement."
				},
				dienstjaren: {
					title: "Dienstjaren",
					explanation: "Het aantal jaren en hele maanden die liggen tussen de laatste van de volgende data: de datum van indiensttreding/de toetredingsleeftijd tot de pensioenregeling en de pensioendatum; of het aantal jaren en hele maanden die liggen tussen de laatste van de volgende data: de ingangsdatum van de pensioenregeling/de datum van indiensttreding/de toetredingsleeftijd tot de pensioenregeling en de pensioendatum of, bij eerdere uitdiensttreding, de datum waarop de arbeidsovereenkomst wordt beëindigd; en de fictieve dienstjaren door inkomende (collectieve) waardeoverdracht. Als en zolang de deelnemer is vrijgesteld van premiebetaling wegens arbeidsongeschiktheid, gelden ook de jaren tussen de datum waarop de arbeidsovereenkomst is beëindigd en uiterlijk de pensioendatum als dienstjaren."
				},
				echtscheiding: {
					title: "Echtscheiding",
					explanation: "De beëindiging van het huwelijk, scheiding van tafel en bed en de beëindiging van het geregistreerd partnerschap, anders dan door de dood, vermissing of omzetting naar een andere huwelijksvorm of een gemeenschappelijke huishouding met dezelfde partner."
				},
				echtscheidingsconvenant: {
					title: "Echtscheidingsconvenant",
					explanation: "Een schriftelijke overeenkomst met als doel het vastleggen van afspraken rondom de beëindiging van het huwelijk of het geregistreerd partnerschap."
				},
				fiscaalMaximaalToegestanePremie: {
					title: "Fiscaal maximaal toegestane premie",
					explanation: "De maximaal toegestane beschikbare premie zoals omschreven in Hoofdstuk IIB van de Wet op de Loonbelasting 1964."
				},
				fonds: {
					title: "Fonds",
					explanation: "Een door de verzekeraar aangeboden beleggingsmogelijkheid."
				},
				fondskosten: {
					title: "Fondskosten",
					explanation: "De fondsbeheerder maakt kosten voor het beheren van de fondsen. De fondskosten bestaan uit de beheervergoeding voor de fondsbeheerder en de overige fondskosten. Bij overige fondskosten kunt u denken aan kosten voor: verslaglegging, accountants, bankkosten, kosten voor marketing, etc. Al deze kosten worden binnen het fonds zelf verrekend. De kosten zijn dus verwerkt in de koers en dus ook in het gerealiseerde rendement."
				},
				fondseninformatieScildonPensioen: {
					title: "Fondseninformatie Scildon Pensioen",
					explanation: "Informatie die de verzekeraar aan het einde van een kalenderkwartaal opstelt over de fondsen. Hierin staan onder andere de fondskosten en de beheerkosten per fonds. De meest recente versie van de Fondseninformatie Scildon Pensioen staat op Scildon Online Pensioenen."
				},
				franchise: {
					title: "Franchise",
					explanation: "Het salarisdeel waarover geen premie wordt berekend en geen pensioen wordt opgebouwd. "
				},
				geldswaarde: {
					title: "Geldswaarde",
					explanation: "Het aantal aanwezige participaties per fonds die in het beleggingsdepot aanwezig zijn, vermenigvuldigd met de koers van elk fonds. De geldswaarde wordt uitgedrukt in euro’s."
				},
				gemeenschappelijkeHuishouding: {
					title: "Gemeenschappelijke huishouding",
					explanation: "Een huishouding die minimaal zes aaneengesloten maanden door de (gewezen) deelnemer wordt gevoerd met één partner op hetzelfde adres. De (gewezen) deelnemer en de partner moeten dit kunnen aantonen via de Basisregistratie Personen (BRP). De partner mag geen bloed- of aanverwant zijn in de rechte lijn of in de tweede graad van de (gewezen) deelnemer."
				},
				gemiddeldParttimepercentage: {
					title: "Gemiddeld parttimepercentage",
					explanation: "Zie parttimepercentage"
				},
				geregistreerdPartnerschap: {
					title: "Geregistreerd partnerschap",
					explanation: "Het geregistreerd partnerschap is in dit pensioenreglement hetzelfde als een huwelijk."
				},
				gewezenDeelnemer: {
					title: "Gewezen deelnemer",
					explanation: "De man of vrouw die pensioenaanspraken op grond van het Pensioenreglement heeft opgebouwd, maar niet meer deelneemt aan de pensioenregeling door een andere reden dan door overlijden of het bereiken van de werkelijke pensioendatum."
				},
				gewezenPartner: {
					title: "Gewezen partner",
					explanation: "De man of vrouw van wie het huwelijk met de (gewezen) deelnemer of gepensioneerde is beëindigd door echtscheiding. Of de man of vrouw van wie de gemeenschappelijke huishouding met de (gewezen) deelnemer of gepensioneerde is beëindigd om andere redenen dan door overlijden of vermissing of het aangaan van een huwelijk met dezelfde partner waarmee een gemeenschappelijke huishouding werd gevormd."
				},
				gewogenGemiddeldeProductRendement: {
					title: "Gewogen gemiddelde productrendement",
					explanation: "Bij de berekening van de voorbeeldkapitalen houdt de verzekeraar rekening met het beleggingsschema volgens de methode ‘Beheerd Beleggen’. Het gewogen gemiddelde productrendement geeft een geschat rendement weer van beleggingen in de fondsen. Daarbij wordt rekening gehouden met de verschillende verhoudingen tussen de verschillende rendementen van de fondsen waarin een (gewezen) deelnemer belegt tot aan de pensioendatum."
				},
				handelsdag: {
					title: "Handelsdag",
					explanation: "Een handelsdag is iedere dag waarop de verzekeraar geopend is. Dat is iedere werkdag van het jaar, behalve de algemeen erkende feestdagen in Nederland. Ook Hemelvaartsdag en Goede Vrijdag vallen hieronder. Ook Euronext Amsterdam en de banken in Nederland moeten geopend zijn voor het (laten) uitvoeren van transacties in financiële instrumenten. De beheerder van de beleggingsinstelling die bij de transactie betrokken is moet ook aanwezig zijn en de beurzen en banken in het buitenland, die van belang zijn voor de beleggingsinstelling, moeten geopend zijn voor het (laten) uitvoeren van transacties in financiële instrumenten."
				},
				heralloceren: {
					title: "Heralloceren",
					explanation: "Het aanpassen van de strategische beleggingsmix aan het beleggingsschema van Beheerd Beleggen. "
				},
				herbalanceren: {
					title: "Herbalanceren",
					explanation: "Doordat de koers van participaties verandert, kan de samenstelling van de beleggingsportefeuille gaan afwijken van de strategische beleggingsmix uit het beleggingsschema van Beheerd Beleggen (scheefgroei). De beleggingsmix wordt dan door de verzekeraar weer teruggebracht binnen de bandbreedte van de strategische beleggingsmix."
				},
				historischRendement: {
					title: "Historisch rendement",
					explanation: "Het historisch rendement van een fonds is het gemiddelde van het werkelijk behaalde rendement van een fonds over de afgelopen 20 jaar. Als een fonds korter dan 4 jaar bestaat, bepaalt de Autoriteit Financiële Markten (AFM) welk rendement het fonds dan mag gebruiken als historisch rendement. Het historisch rendement is een brutorendement."
				},
				huwelijk: {
					title: "Huwelijk",
					explanation: "Huwelijk en geregistreerd partnerschap."
				},
				karakterPensioenregeling: {
					title: "Karakter pensioenregeling",
					explanation: "De pensioenregeling bestaat uit twee karakters: premieovereenkomst en uitkeringsovereenkomst. Het karakter van de regeling bepaalt wie het financiele risico draagt als de pensioenverwachtingen niet uitkomen: de werkgever doordat hij meer premie moet gaan betalen (in dat geval gaat het om een uitkeringsovereenkomst) of de werknemer doordat deze lagere pensioenuitkeringen krijgt (in dat geval gaat het om een premieovereenkomst)."
				},
				klantprofiel: {
					title: "Klantprofiel",
					explanation: "Het klantprofiel bestaat uit een aantal vragen die de (gewezen) deelnemer moet beantwoorden. Zo kan de verzekeraar inventariseren wat bij deze pensioenverzekering de doelstellingen, financiële situatie, risicobereidheid, kennis en ervaring van de (gewezen) deelnemer is. Aan de hand van het klantprofiel kan de verzekeraar beoordelen wat voor de (gewezen) deelnemer een juiste balans is tussen de kans op een goed rendement en het beleggingsrisico."
				},
				kinderen: {
					title: "Kind(eren)",
					explanation: "Het kind met wie de deelnemer voor de pensioendatum een familierechtelijke band heeft, zoals omschreven in boek 1 van het Burgerlijk Wetboek; en/of het stief- of pleegkind van de deelnemer, dat tot het overlijden van de deelnemer duurzaam wordt onderhouden en opgevoed door de deelnemer alsof het een eigen kind is. Het onderhoud en de opvoeding moet vóór de pensioendatum zijn begonnen en het pleegkind moet door de deelnemer schriftelijk bij de werkgever zijn aangemeld; en het kind dat nog geen 21 jaar is, of studerende is volgens de Wet studiefinanciering 2000 en nog geen 27 jaar is.  "
				},
				koers: {
					title: "Koers",
					explanation: "De officiële openingskoers of afgifteprijs die geldt voor participaties van het fonds op de koersdatum. "
				},
				koersdatum: {
					title: "Koersdatum",
					explanation: "Het moment waarop de koers van participaties wordt vastgesteld. Dit is de eerste handelsdag die volgt op de dag waarop een opdracht tot het aan- of verkopen van participaties wordt gegeven, vermeerderd met de periode die het fonds nodig heeft om de aan- of verkoopopdracht te verwerken. De laatstgenoemde periode wordt beschreven in de handelssystematiek van de betreffende fondsen. De handelssystematiek van de fondsen is opgenomen in de Fondseninformatie Scildon Pensioen."
				},
				scildonOnlinePensioenen: {
					title: "Scildon Online Pensioenen",
					explanation: "Een afgeschermd en beveiligd deel van de website van Scildon, waarop informatie staat over de pensioenverzekering. Deze website is via unieke inloggegevens toegankelijk voor de werkgever, de (gewezen) deelnemer en de pensioenadviseur die een pensioenregeling hebben bij de verzekeraar."
				},
				negatieveRisicopremies: {
					title: "Negatieve risicopremies",
					explanation: "Een vergoeding die de (gewezen) deelnemer ontvangt van de verzekeraar voor het risico dat het pensioenkapitaal niet of niet geheel tot uitkering komt. Negatieve risicopremies worden omgerekend naar participaties en toegevoegd aan het beleggingsdepot."
				},
				nettorendement: {
					title: "Nettorendement",
					explanation: "Dit is het rendement dat de (gewezen) deelnemers met hun beleggingen in de fondsen behalen. Het wordt berekend door het brutorendement dat door het fonds is behaald te verminderen met de fondskosten en de beheerkosten van de verzekeraar. "
				},
				participaties: {
					title: "Participaties",
					explanation: "Het tot op drie decimalen nauwkeurig berekende aantal eenheden van een fonds."
				},
				partner: {
					title: "Partner",
					explanation: "De partner is de echtgenoot/echtgenote of geregistreerde partner van de (gewezen) deelnemer; of de persoon met wie de (gewezen) deelnemer een gemeenschappelijke huishouding voert. Iedere deelnemer kan maar één partner hebben."
				},
				partnerrelatie: {
					title: "Partnerrelatie",
					explanation: "Het huwelijk, het geregistreerd partnerschap of de gemeenschappelijke huishouding."
				},
				parttimepercentage: {
					title: "Parttimepercentage",
					explanation: "De verhouding tussen het aantal arbeidsuren dat met de werkgever is overeengekomen en de werktijd. Het actuele parttimepercentage is het parttimepercentage van het betreffende jaar. Het gemiddelde parttimepercentage is het parttimepercentage in alle dienstjaren, gedeeld door de dienstjaren."
				},
				peildatum: {
					title: "Peildatum",
					explanation: "De datum waarop jaarlijks de hoogte van de pensioenaanspraken en de pensioenpremies voor de deelnemers wordt vastgesteld. Dit gebeurt op basis van de leeftijd van de deelnemers, de pensioengrondslag, het parttimepercentage, de tarieven en de uitvoeringskosten voor de regeling die dan gelden. "
				},
				peiljaar: {
					title: "Peiljaar",
					explanation: "Een jaar gerekend vanaf de peildatum tot de volgende peildatum."
				},
				pensioenaanspraak: {
					title: "Pensioenaanspraak",
					explanation: "Het recht op een pensioen dat nog niet is ingegaan."
				},
				pensioenadviseur: {
					title: "Pensioenadviseur",
					explanation: "De persoon die de werkgever adviseert over het pensioen van zijn werknemers."
				},
				pensioendatum: {
					title: "Pensioendatum",
					explanation: "De eerste dag van de maand waarin de (gewezen) deelnemer de pensioenleeftijd bereikt."
				},
				pensioengerechtigde: {
					title: "Pensioengerechtigde",
					explanation: "Persoon voor wie op grond van een Pensioenovereenkomst een pensioen is ingegaan."
				},
				pensioengevendSalaris: {
					title: "Pensioengevend salaris",
					explanation: "Het deel van het salaris waarover u pensioen opbouwt. Het pensioengevend salaris kan nooit hoger zijn dan het vastgestelde wettelijk maximum. Over het salaris dat hoger is dan dit bedrag, worden geen pensioenaanspraken verkregen."
				},
				pensioengrondslag: {
					title: "Pensioengrondslag",
					explanation: "Het pensioengevend salaris verminderd met de franchise. Als de deelnemer minder dan de volledige werktijd werkzaam is, wordt de pensioengrondslag vermenigvuldigd met een parttimepercentage."
				},
				pensioenkapitaal: {
					title: "Pensioenkapitaal",
					explanation: "Het kapitaal dat ontstaat door de betaalde beschikbare premies en de eventuele vrijwillige eigen bijdragen, én het daarop behaalde (positieve en negatieve) productrendement. Dit kapitaal wordt niet uitgekeerd, maar wordt alleen gebruikt voor het aankopen van een ouderdoms- en/of een partnerpensioen bij overlijden na pensioendatum. De hoogte van het pensioenkapitaal is gelijk aan de geldswaarde."
				},
				pensioenlasten: {
					title: "Pensioenlasten",
					explanation: "De premies en de kosten die voor de pensioenverzekering zijn verschuldigd. "
				},
				pensioenovereenkomst: {
					title: "Pensioenovereenkomst",
					explanation: "De afspraken tussen de werkgever en de werknemer over het pensioen als onderdeel van de arbeidsvoorwaarden."
				},
				pensioenrecht: {
					title: "Pensioenrecht",
					explanation: "Recht op een ingegaan pensioen."
				},
				pensioenreglement: {
					title: "Pensioenreglement",
					explanation: "De door de verzekeraar opgestelde pensioenregeling, waarin de inhoud van de Pensioenovereenkomst en de rechten en de plichten tussen de (gewezen) deelnemers en de verzekeraar zijn opgenomen."
				},
				pensioenverzekering: {
					title: "Pensioenverzekering",
					explanation: "Alle rechten en plichten van de verzekeraar, de werkgever en de (gewezen) deelnemers die voortkomen uit de Verzekeringsvoorwaarden Scildon Pensioen, de geldende Pensioenovereenkomst, het Pensioenreglement, de Uitvoeringsovereenkomst en de relevante wet- en regelgeving."
				},
				pessimistischRendement: {
					title: "Pessimistisch rendement",
					explanation: "Een schatting van het te behalen brutorendement als de beleggingsresultaten langdurig tegenvallen. De AFM bepaalt het pessimistisch rendement."
				},
				premieovereenkomst: {
					title: "Premieovereenkomst",
					explanation: "Een overeenkomst waarin vooraf vaststaat hoe hoog de pensioenpremie is die de werkgever betaalt voor iedere deelnemer. De (gewezen) deelnemers weten vooraf niet hoe hoog hun pensioen wordt. De pensioenpremies worden belegd om een pensioenkapitaal op te bouwen. Met dit pensioenkapitaal kopen zij pensioenuitkeringen aan. "
				},
				Premievervaldatum: {
					title: "Premievervaldatum",
					explanation: "De dag(en) in een kalenderjaar waarop (een deel van) de jaarlijks verschuldigde premie en de kosten van de pensioenverzekering moeten zijn betaald. "
				},
				productrendement: {
					title: "Productrendement",
					explanation: "Het nettorendement, verhoogd met negatieve risicopremies. "
				},
				risicoverzekering: {
					title: "Risicoverzekering",
					explanation: "In de pensioenregeling wordt met risicoverzekering het partnerpensioen bij overlijden voor pensioendatum en het wezenpensioen bij overlijden voor pensioendatum en het Anw-hiaatpensioen en premievrijstelling bij arbeidsongeschiktheid bedoeld. De risicoverzekering keert uit als de deelnemer overlijdt of eventueel arbeidsongeschikt wordt. De risicoverzekering vervalt bij het einde van de deelname (anders dan door overlijden of arbeidsongeschiktheid). "
				},
				scheiding: {
					title: "Scheiding",
					explanation: "Zie Echtscheiding"
				},
				stijgendePremiestaffel: {
					title: "Stijgende premiestaffel",
					explanation: "De beschikbare premie is toegezegd in de vorm van een leeftijdsafhankelijke, stijgende premiestaffel. Dat wil zeggen dat de beschikbare premie stijgt naarmate de deelnemer ouder wordt en overgaat naar een volgende leeftijdsgroep. De staffel is ingedeeld in leeftijdsgroepen van vijf jaar. Als de verzekeraar de beschikbare premie doorbetaalt door premievrijstelling bij arbeidsongeschiktheid en de betreffende deelnemer gaat over naar een volgende leeftijdsgroep, dan wordt ook de door de verzekeraar doorbetaalde premie verhoogd. "
				},
				gelijkblijvendePremiestaffel: {
					title: "Gelijkblijvende premiestaffel",
					explanation: "De beschikbare premie is toegezegd in de vorm van een gelijkblijvende premiestaffel. Dat wil zeggen dat het beschikbare premiepercentage een vast percentage is. Ook naarmate de deelnemer ouder wordt en overgaat naar een volgende leeftijdsgroep. De staffel is ingedeeld in leeftijdsgroepen van vijf jaar. Als de verzekeraar de beschikbare premie doorbetaalt door premievrijstelling bij arbeidsongeschiktheid en de betreffende deelnemer gaat over naar een volgende leeftijdssgroep, dan wordt ook de door de verzekeraar doorbetaalde premie niet verhoogd, maar blijft het een vast premiepercentage."
				},
				startdatumVanDeelname: {
					title: "Startdatum van deelname",
					explanation: "De datum waarop een deelnemer volgens het Pensioenreglement begint met het verkrijgen van pensioenaanspraken."
				},
				strategischeBeleggingsmix: {
					title: "Strategische beleggingsmix",
					explanation: "De verhouding waarin wordt belegd in de verschillende beleggingscategorieën zoals bijvoorbeeld aandelen, obligaties en geldmarkt. In het Pensioenreglement wordt bepaald of de strategische beleggingsmix wordt vastgesteld volgens het beleggingsschema van Beheerd Beleggen of dat de (gewezen) deelnemer zelf de strategische beleggingsmix kiest."
				},
				toetredingsleeftijd: {
					title: "Toetredingsleeftijd",
					explanation: "De leeftijd waarop een werknemer volgens het Pensioenreglement mag starten met deelnemen aan de pensioenregeling."
				},
				uitvoeringsovereenkomst: {
					title: "Uitvoeringsovereenkomst",
					explanation: "De overeenkomst tussen de werkgever en de verzekeraar over de uitvoering en de financiering van de Pensioenovereenkomst."
				},
				uitkeringsovereenkomst: {
					title: "Uitkeringsovereenkomst",
					explanation: "Een Pensioenovereenkomst waarbij de deelnemers vooraf precies weten hoe hoog de pensioenuitkeringen zijn waar zij recht op hebben. De hoogte van deze pensioenuitkeringen is gegarandeerd. Vooraf kan niet worden aangegeven hoe hoog de totale premies zijn om de pensioenuitkeringen te kunnen betalen."
				},
				uniformPensioenoverzicht: {
					title: "Uniform Pensioenoverzicht (UPO)",
					explanation: "Op het Uniform Pensioenoverzicht staan de pensioenaanspraken van de (gewezen) deelnemer, zijn/haar partners en zijn/haar kinderen en de geschatte pensioenrechten op de pensioendatum als de deelnemer tot die datum in dienst blijft bij de werkgever. Deelnemers ontvangen jaarlijks een UPO van hun huidige pensioenuitvoerder. Gewezen deelnemers ontvangen jaarlijks een UPO van de pensioenuitvoerder(s) van de oude werkgever(s). Alle pensioenuitvoerders gebruiken hetzelfde pensioenoverzicht met altijd dezelfde indeling, zodat pensioenaanspraken van verschillende pensioenuitvoerders eenvoudig bij elkaar kunnen worden opgeteld. Zo krijgen de (gewezen) deelnemers, hun partners en hun kinderen zicht op de hoogte van de uiteindelijke pensioenuitkeringen bij pensionering, uitdiensttreding, arbeidsongeschiktheid of overlijden. Een pensioenoverzicht is ook in te zien op {{ www.mijnpensioenoverzicht.nl | link }}."
				},
				verzekeraar: {
					title: "Verzekeraar",
					explanation: "Scildon N.V."
				},
				verzekeringsvoorwaardenScildonPensioen: {
					title: "Verzekeringsvoorwaarden Scildon Pensioen",
					explanation: "Algemene verzekeringsvoorwaarden, de beleggingsvoorwaarden en de voorwaarden voor verzekering van premievrijstelling bij arbeidsongeschiktheid die gelden voor de pensioenverzekering."
				},
				voorbeeldkapitaal: {
					title: "Voorbeeldkapitaal",
					explanation: "Het voorbeeldkapitaal is het geschatte pensioenkapitaal dat op de pensioendatum beschikbaar komt als de deelnemer tot aan de pensioendatum deelneemt. Hierbij wordt uitgegaan van een gelijkblijvende pensioengrondslag en een beschikbare premie overeenkomstig de premiestaffel. Ook moet het gewogen gemiddelde productrendement gedurende de hele looptijd ieder jaar worden behaald. Omdat deze uitgangspunten kunnen afwijken van de werkelijke ontwikkeling van de pensioengrondslag en het productrendement, kan het pensioenkapitaal dat op de pensioendatum beschikbaar komt hoger of lager zijn dan het voorbeeldkapitaal."
				},
				waardeoverzicht: {
					title: "Waardeoverzicht",
					explanation: "Model 3 de Ruiter. Dit is een cijfermatig overzicht waarin de deelnemer jaarlijks wordt geïnformeerd over wat er met zijn inleg is gebeurd. De deelnemer ziet hoeveel premie in het voorgaande jaar is betaald voor zijn ouderdomspensioen en het partnerpensioen na pensioendatum, hoeveel rendement hij heeft behaald op zijn beleggingen, welke kosten de verzekeraar daarvan heeft afgetrokken, hoeveel negatieve risicopremies hij heeft ontvangen en hoe hoog het pensioenkapitaal aan het einde van het voorgaande jaar was. Sinds 2020 maakt het Waardeoverzicht onderdeel uit van het UPO, en verstuurt Scildon het niet meer apart."
				},
				wachtperiode: {
					title: "Wachtperiode",
					explanation: "De voor de Wet Werk en Inkomen naar Arbeidsvermogen geldende wachtperiode van 104 weken."
				},
				werkelijkePensioendatum: {
					title: "Werkelijke pensioendatum",
					explanation: "De door de (gewezen) deelnemer gekozen leeftijd waarop het ouderdomspensioen werkelijk ingaat. Deze ligt voor, op of na de pensioendatum en is uiterlijk 5 jaar nadat de (gewezen) deelnemer de AOW leeftijd heeft bereikt."
				},
				werknemer: {
					title: "Werknemer",
					explanation: "De persoon die volgens het burgerlijk recht een arbeidsovereenkomst heeft met de werkgever, met uitzondering van de directeur-grootaandeelhouder zoals bedoeld in artikel 1 van de Pensioenwet."
				},
				werktijd: {
					title: "Werktijd",
					explanation: "Het gebruikelijk aantal arbeidsuren bij de werkgever op basis van een fulltime dienstverband."
				}
			}
		},
		parttimePercentage: {
			title: "Wijzig de gegevens van",
			subheadingChanges: "Wat wilt u wijzigen?",
			subheadingSalaryChanges: "Salariswijzinging",
			newParttimePercentage: "Nieuw parttimepercentage",
			changeStartingDate: "Ingangsdatum wijziging",
			startingDateError: "Dit datum is niet geldig",
			send: "Verstuur",
			confirmationPage: {
				title: "Bevestiging - Wijzig de gegevens van",
				body: "U heeft het parttimepercentage van uw medewerker gewijzigd. Zodra de wijziging is verwerkt, zie u dit terug in het overzicht van uw medewerkers.",
				button: "Afsluiten"
			}
		}
	}
}

export default options
